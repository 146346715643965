<template>
<div>
    <a-row>
        <a-col :span="4">tree</a-col>
        <a-col :span="20">
            <a-row>
                <a-col :span="3">
                    <a-form-item lable=""><a-button type="danger">移除所选项目</a-button></a-form-item>
                </a-col>
                <a-col :span="18">
                    <a-form-model layout="inline">
                        <a-form-model-item lable="">
                            <a-input></a-input>
                        </a-form-model-item>
                            <a-form-model-item lable="">
                                <a-radio-group >
                                    <a-radio :value="1">使用中</a-radio>
                                    <a-radio :value="2">已停用</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item lable="">
                                <a-button type="primary">搜索</a-button>
                            </a-form-model-item>
                    </a-form-model>
                </a-col>
                <a-col :span="3" style="text-align: right"><a-button type="primary" @click="showDrawerNewItem()">添加检验项目</a-button></a-col>
            </a-row>
            <a-row>
                <a-col :span="24">
                    <table class="layui-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>样品类型分类</th>
                            <th>检验项目</th>
                            <th>检验方法</th>
                            <th>状态</th>
                            <th>执行标准</th>
                            <th>执行标准编号</th>
                            <th>检验标准</th>
                            <th>检验标准编号</th>
                            <th>检验标准细则编号</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </a-col>
            </a-row>
        </a-col>
    </a-row>
<!--    <div v-if="edit_infoNewItem"> :edit_infoNewItem="edit_infoNewItem" -->
        <NewItem  :edit_timeNewItem="edit_timeNewItem" :close="onCloseDrawerNewItem" :visible="drawer_visibleNewItem"></NewItem>
<!--    </div>-->

</div>
</template>

<script>
    import NewItem from './NewItem/NewItem.vue';

    export default {
        name: "sampletype",
        components: { NewItem},
        data() {
            return {

                edit_timeNewItem: 0,
                edit_infoNewItem: false,
                drawer_visibleNewItem: false,
            }
        },
        mounted() {

        },
        methods: {
            // 定义 打开抽屉时的 新建/编辑
            showDrawerNewItem() {
                this.edit_timeNewItem = new Date() / 1;
                //this.edit_infoNewItem = item;
                this.drawer_visibleNewItem = true;
            },
            // 定义 关闭抽屉时的
            onCloseDrawerNewItem() {
                this.Get_jc_item();
                this.drawer_visibleNewItem = false;
            },
        },
    }
</script>

<style scoped>

</style>
