<template>
    <!--:visible="visible"  赋值 显示变量-->
    <!--@close="onClose"    赋值 关闭函数-->
    <a-drawer title="`${edit_infoNewItem.id===0?'新建':'编辑'}检测项目`" width="92%" placement="right" :closable="true" :visible="visible" @close="onClose">
        <div>
            <a-row style="padding-bottom: 25px">
                <a-form-model layout="inline" v-bind="formCreat">
                    <a-col :span="6">
                        <a-form-model-item style="padding: 0; margin: 0">执行标准：</a-form-model-item>
                        <a-form-model-item>
                            <a-select v-model="formCreat.name" style="width: 320px;" @change="check_bz_idChange">
                                <a-select-option :value="item.id"  v-for="(item,key) in Executive_bz_list_list">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item style="padding: 0; margin: 0">执行标准编号：</a-form-model-item>
                        <a-form-model-item>
                            <a-input v-model="formCreat.serial"></a-input>
                        </a-form-model-item>
                        <a-form-model-item lable="">
                            <a-button type="primary">保存</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-form-model>
            </a-row>
            <a-row>
                <a-col :span="11" style="border: solid 1px #efefef; padding: 15px">
                    <a-row>
                        <a-col :span="24">
                            <a-form-model layout="inline">
                                <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                    <a-input placeholder="输入检测项目名称、拼音首字母进行搜索" style="width: 300px"></a-input>
                                </a-form-model-item>
                                <a-form-model-item lable="" :wrapper-col="{ span: 24,}">
                                    <a-button type="primary">搜索</a-button>
                                </a-form-model-item>
                            </a-form-model>
                        </a-col>
                    </a-row>
                    <table class="layui-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>检验标准分类</th>
                            <th>方法分类</th>
                            <th>检验项目名称</th>
                            <th>检验方法</th>
                            <th>检验标准细则</th>
                            <th>标准值</th>
                            <th>单位</th>
                            <th>备注</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </a-col>
                <a-col :span="11" offset="1" style="border: solid 1px #efefef; padding: 15px">
                    <a-row>
                      <a-col :span="24">
                        <a-form-model layout="inline">
                            <a-form-model-item lable="">
                                <a-input placeholder="输入检测项目名称、拼音首字母进行搜索" style="width: 300px"></a-input>
                            </a-form-model-item>
                            <a-form-model-item lable="">
                                <a-button type="primary">搜索</a-button>
                            </a-form-model-item>
                        </a-form-model>
                      </a-col>
                    </a-row>
                    <a-row>
                       <a-col :span="24">
                        <table class="layui-table" >
                            <thead>
                            <tr>
                                <th></th>
                                <th>检验项目</th>
                                <th>方法类别</th>
                                <th>检验方法</th>
                                <th>检验标准</th>
                                <th>检验细则</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,key) in Jc_item_list_list">
                                <td><a-checkbox v-model="item.check"></a-checkbox></td>
                                <td>{{item.method_name}}</td>
                                <td>{{item.result_type}}</td>
                                <td>{{item.method_name}}</td>
                                <td>{{item.check_bz_name}}</td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                       </a-col>
                    </a-row>
                </a-col>
            </a-row>

            <div class="cscontainer">
                <div class="csconleft">

<!--                    <a-input-search v-model="left_search" placeholder="请输入因子名称" @change="makeSearchLeft" enter-button @search="makeSearchLeft"/>-->
                    <div class="csconleft-mid">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th>
                                    <a-checkbox v-model="all_check"></a-checkbox>
                                </th>
                                <th><span @click="caretnameupclick()" v-if="sort_info.data_name===1">名称</span>
                                    <span @click="caretnamedownclick()" v-if="sort_info.data_name===2">名称</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretnameupclick()" v-if="sort_info.data_name===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretnamedownclick()" v-if="sort_info.data_name===2"/>
                                </th>
                                <th><span @click="caretcalupclick()" v-if="sort_info.data_sort===1">化学式</span>
                                    <span @click="caretcaldownclick()" v-if="sort_info.data_sort===2">化学式</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretcalupclick()" v-if="sort_info.data_sort===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretcaldownclick()" v-if="sort_info.data_sort===2"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,key) in left_list" :key="key">
                                <td>
                                    <a-checkbox v-model='item.check'></a-checkbox>
                                </td>
                                <td>{{item.item_name}}</td>
                                <td>{{item.method_name}}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="csconmid">
                    <div class="csconmid-btn">
                        <div class="csconmid-btn-b" @click="allrightclick()">
                            <a-icon type="double-right"/>
                        </div>
                        <div class="csconmid-btn-b" @click="getPushIds()">
                            <a-icon type="right"/>
                        </div>
                        <div class="csconmid-btn-b" @click="selectleftclick()">
                            <a-icon type="left"/>
                        </div>
                        <div class="csconmid-btn-b" @click="allleftclick()">
                            <a-icon type="double-left"/>
                        </div>
                    </div>
                </div>
                <div class="csconleft">
<!--                    <a-input-search v-model="right_search" placeholder="请输入因子名称" @change="makeSearchRight" enter-button @search="makeSearchRight"/>-->
                    <div class="csconleft-mid">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th>
                                    <a-checkbox v-model='all_check_r'></a-checkbox>
                                </th>
                                <th>

                                    <span @click="caretnameupclick1()" v-if="sort_info1.data_name===1">名称</span>
                                    <span @click="caretnamedownclick1()" v-if="sort_info1.data_name===2">名称</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretnameupclick1()" v-if="sort_info1.data_name===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretnamedownclick1()" v-if="sort_info1.data_name===2"/>
                                </th>
                                <th>
                                    <span @click="caretcalupclick1()" v-if="sort_info1.data_sort===1">化学式</span>
                                    <span @click="caretcaldownclick1()" v-if="sort_info1.data_sort===2">化学式</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretcalupclick1()" v-if="sort_info1.data_sort===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretcaldownclick1()" v-if="sort_info1.data_sort===2"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="( item,key) in right_list" :key="key">
                                <td>
                                    <a-checkbox v-model='item.check'></a-checkbox>
                                </td>
                                <td>{{item.name}}</td>
                                <td>{{item.chemical}}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </div>
    </a-drawer>
</template>

<script>
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_infoNewItem'],

        data: () => ({
            formCreat:{
                name:'',
                serial:'',
            },
            Executive_bz_list_list:[],

            Jc_item_list_list:[],
            left_list: [],
            left_search: '',
            right_list: [],
            right_search: '',
            all_check: false,
            all_check_r: false,
            allfactorData: [],
            selectfactorData: [],
            sort_info: {
                data_name: 1,
                data_sort: 1,
            },
            sort_info1: {
                data_name: 1,
                data_sort: 1,
            },


        }),
        watch: {
            all_check() {
                this.left_list.map((item, key) => {
                    this.$set(this.left_list[key], 'check', this.all_check)
                })
            },
            all_check_r() {
                this.right_list.map((item, key) => {
                    this.$set(this.right_list[key], 'check', this.all_check_r)
                })
            },
        },
        mounted() {
           this.Jc_item_list()//检测项目列表
           this.Executive_bz_list()//执行标准列表
        },
        methods: {



            makeSearchLeft() {
                if (this.left_search == '') {
                    this.left_list = JSON.parse(JSON.stringify(this.allfactorData))
                } else {
                    let list = [];
                    this.allfactorData.map((item) => {
                        if (item.name.indexOf(this.left_search) !== -1) {
                            list.push(item)
                        } else {
                            if (item.chemical.indexOf(this.left_search) !== -1) {
                                list.push(item)
                            }
                        }
                    })
                    this.left_list = list
                }
            },
            makeSearchRight() {
                if (this.right_search == '') {
                    this.right_list = JSON.parse(JSON.stringify(this.selectfactorData))
                } else {
                    let list = [];
                    this.selectfactorData.map((item) => {
                        if (item.name.indexOf(this.right_search) !== -1) {
                            list.push(item)
                        } else {
                            if (item.chemical.indexOf(this.right_search) !== -1) {
                                list.push(item)
                            }
                        }
                    })
                    this.right_list = list
                }
            },
            Jc_item_list(data_name = 1, data_sort = 1) {
                this.$sa0.post({
                    url: this.$api('剔除已经选中的因子列表'),
                    data: {
                        det_method_id: this.info.id,
                        data_name: data_name,
                        data_sort: data_sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.allfactorData = response.data.list.map((item) => {
                                item.check = false
                                return item
                            })
                            this.makeSearchLeft()
                            this.Get_detmethodfactoryByid()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Get_detmethodfactoryByid(data_name = 1, data_sort = 1) {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfactoryByid'),
                    data: {
                        det_method_id: this.info.id,
                        data_name: data_name,
                        data_sort: data_sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.selectfactorData = response.data.list.map((item) => {
                                return item;
                            })
                            this.makeSearchRight()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            allrightclick() {
                let ids = []
                this.left_list.map((item) => {
                    ids.push(Number(item.id))
                })
                this.selectfactorData.map((item) => {
                    ids.push(Number(item.id))
                })
                this.selectrightclick(ids, 1)
            },
            selectleftclick() {
                let ids = []
                let del_ids = []
                this.right_list.map((item) => {
                    if (!item.check) {
                        ids.push(Number(item.id))
                    } else {
                        del_ids.push(Number(item.id))
                    }
                })
                this.selectfactorData.map((item) => {
                    if (del_ids.indexOf(Number(item.id)) === -1) {
                        ids.push(Number(item.id))
                    }
                })
                this.selectrightclick(ids, 2)
            },
            allleftclick() {
                this.selectrightclick([], 2)
            },
            getPushIds() {
                let ids = []
                this.left_list.map((item) => {
                    if (item.check) ids.push(Number(item.id))
                })
                this.selectfactorData.map((item) => {
                    if (ids.indexOf(Number(item)) === -1) ids.push(Number(item.id))
                })
                this.selectrightclick(ids, 1)
            },
            selectrightclick(ids, type) {
                let id = []
                ids.map((item) => {
                    if (id.indexOf(item) == -1) {
                        id.push(item)
                    }
                })
                this.$sa0.post({
                    url: this.$api('Set_ablility'),
                    data: {
                        type,
                        det_method_id: this.info.id,
                        factory_ids: id.join(','),
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Jc_item_list()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            caretnameupclick() {
                this.sort_info.data_name = 2
                this.Jc_item_list(2, this.sort_info.data_sort)
            },
            caretnamedownclick() {
                this.sort_info.data_name = 1
                this.Jc_item_list(1, this.sort_info.data_sort)
            },
            caretcalupclick() {
                this.sort_info.data_sort = 2
                this.Jc_item_list(this.sort_info.data_name, 2)
            },

            caretcaldownclick() {
                this.sort_info.data_sort = 1
                this.Jc_item_list(this.sort_info.data_name, 1)
            },


            caretnameupclick1() {
                this.sort_info1.data_name = 2
                this.Get_detmethodfactoryByid(2, this.sort_info1.data_sort)
            },
            caretnamedownclick1() {
                this.sort_info1.data_name = 1
                this.Get_detmethodfactoryByid(1, this.sort_info1.data_sort)
            },
            caretcalupclick1() {
                this.sort_info1.data_sort = 2
                this.Get_detmethodfactoryByid(this.sort_info1.data_name, 2)
            },

            caretcaldownclick1() {
                this.sort_info1.data_sort = 1
                this.Get_detmethodfactoryByid(this.sort_info1.data_name, 1)
            },

            // Jc_item_list() {
            //     this.$sa0.post({
            //         url: this.$api('Jc_item_list'),
            //         data: {},
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 this.Jc_item_list_list = response.data.list.map((item) => {
            //                     item.check = false
            //                     return item;
            //                 });
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },
            //执行标准、执行编号
            check_bz_idChange(e) {
                console.log(e)
                this.Executive_bz_list_list.map((item) => {
                    if (item.id === e) {
                        this.formCreat.serial = item.serial
                    }
                })
            },
            Executive_bz_list() {
                this.$sa0.post({
                    url: this.$api('Executive_bz_list'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Executive_bz_list_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

        }
    }
</script>

<style scoped>
    .cscontainer{ width: 100%; display: flex; height: 100%}

    .csconmid{ width: 100px; position: relative}

    .csconleft{ width: 400px; border: 1px solid #FAFAFA; padding: 10px; overflow-y: auto; height: 800px}

    .csconmid-btn{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)}

    .csconmid-btn .csconmid-btn-b{ width: 30px; height: 30px; background: #1890ff; color: #ffffff; text-align: center; line-height: 25px; margin: 10px; border-radius: 2px; cursor: pointer}

    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
</style>
